import { Container } from '@mui/material';

export const PwaLayout = ({ children, ...props }: any) => {
  return (
    <Container
      sx={{
        maxWidth: '500px',
        height: '100vh',
        backgroundImage: 'url("/fond.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {children}
    </Container>
  );
};
