import { AddCircle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import Chronicles from 'components/Chronicles/Chronicles';
import ButtonCustom from 'components/common/ButtonCustom/ButtonCustom';
import DatePickerCustom from 'components/common/DatePickerCustom/DatePickerCustom';
import { PageContainer } from 'components/common/PageContainer';
import GraphAMCharts from 'components/GraphAMCharts/GraphAMCharts';
import {
  AEC,
  DAYS,
  MAX,
  MONTH,
  SEMESTER,
  WEEK,
  YEAR,
} from 'constants/MeasurementFilterPeriod';
import { getLabelFromMeasurer, keepOnlyCalculators } from 'utils/MeasurerUtils';
import { StatisticsDialog } from '../../components/StatisticsDialog/StatisticsDialog';
import { Periodicity } from 'constants/Periodicity';
import {
  chain,
  filter,
  forEach,
  isEmpty,
  isEqual,
  isUndefined,
  map,
  find,
  orderBy,
  get
} from 'lodash';
import Measurer from 'models/Measurer';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlarmTriggerRequest from 'services/commons/AlarmTriggerRequest';
import AlarmTriggerService from 'services/commons/AlarmTriggerService';
import MeasurementRequest from 'services/commons/MeasurementRequest';
import MeasurementService from 'services/commons/MeasurementService';
import MeasurementStatisticsRequest from 'services/commons/MeasurementStatisticsRequest';
import MeasurerService from 'services/commons/MeasurerService';
import { OrderByDirection } from 'services/commons/PagedRequest';
import { DateRange } from 'types/DateRange';
import FileViewerCustom from '../../components/FileViewer';
import { LocationService } from 'services/commons/LocationService';
import { Site } from 'models/Site';
import LocationRequest from 'services/commons/LocationRequest';
import { LocationType } from 'constants/LocationType';
import DeviceService from 'services/commons/DeviceService';
import SelectCustom from 'components/common/SelectCustom/SelectCustom';
import Device from 'models/Device';

const GRANULARITY_LIMIT = 1600;


const computeBestPeriodicity = (
  beginDate: Moment,
  endDate: Moment,
): Periodicity => {
  const diffDays = endDate.diff(beginDate, 'days');
  if (diffDays > GRANULARITY_LIMIT) return Periodicity.WEEK;
  const diffHours = endDate.diff(beginDate, 'hours');
  if (diffHours > GRANULARITY_LIMIT) return Periodicity.DAY;
  return Periodicity.HOUR;
};

const formatDateFromPeriodicity = (
  date: Moment,
  periodicity: Periodicity,
): string => {
  if (periodicity === Periodicity.WEEK) return date.format('YYYYWW');
  if (periodicity === Periodicity.DAY) return date.format('YYYYMMDD');
  return date.format('YYYYMMDDHH');
};

function Measurements(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedSite, setSelectedSite] = useState<Site|null>(null);
  const siteService = new LocationService<Site>();
  let locationRequest = new LocationRequest().withLocationType(
    LocationType.SITE,
  );
  const {
    isLoading: isSitesLoading,
    data: sites,
  } = useQuery(
    'sites',
    () =>
      siteService
        .getFilteredLocationRequest<Site>(locationRequest)
        .then((res) => res),
    { refetchOnMount: 'always' } as any,
  );


  const [selectedDevice, setSelectedDevice] = useState<Device|null>(null);
  const deviceService = new DeviceService();
  const {
    isLoading: isDevicesLoading,
    data: devices,
  } = useQuery('devices_measurements', () => deviceService.getAll().then((res) => res), {
    refetchOnMount: 'always',
    cacheTime: 0,
  });

  useEffect(() => {
    const targetSiteId = parseInt(searchParams.get('site')||'');
    if(!targetSiteId) return;
    if(isNaN(targetSiteId)) return;
    if(isDevicesLoading) return;
    if(!devices) return;
    if(isSitesLoading) return;
    if(!sites) return;
    const targetDevice:Device|undefined = find(devices, (device:Device)=> get(device, 'location.id') === targetSiteId);
    const targetDeviceParentLocationId = get(targetDevice, 'location.parentLocation.id')
    if(targetDeviceParentLocationId){
      const targetSite:Site|undefined = find(sites, {id:targetDeviceParentLocationId});
      targetSite && setSelectedSite(targetSite);
      targetDevice && setSelectedDevice(targetDevice);
    }

  }, [devices,sites,isSitesLoading, isDevicesLoading, searchParams]);


  const [displayChronicles, setDisplayChronicles] = useState<Boolean>(false);
  const [statsDialogOpen, setStatsDialogOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const storedPeriod = localStorage.getItem('period');
  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState<string>(
    storedPeriod ? JSON.parse(storedPeriod!) : MONTH,
  );
  const [dateRange, setDateRange] = useState<DateRange>(
    localStorage.getItem('dateRange')
      ? {
          endDate: moment(
            JSON.parse(localStorage.getItem('dateRange')!).endDate,
          ),
          beginDate: moment(
            JSON.parse(localStorage.getItem('dateRange')!).beginDate,
          ),
        }
      : new DateRange(),
  );

  const queryParams = useMemo(() => {
    const periodicity = computeBestPeriodicity(
      dateRange.beginDate,
      dateRange.endDate,
    );
    return { periodicity, dateRange };
  }, [dateRange]);

  const measurerService = new MeasurerService();
  const measurementService = new MeasurementService();

  const alarmTriggerService = new AlarmTriggerService();

  // pour le select
  const [measurerSelected, setMeasurerSelected] = useState<Measurer | null>(
    null,
  );
  // pour avoir la liste des measurer actuellement affichés sur le graph
  const [measurersDisplayed, setMeasurersDisplayed] = useState<Measurer[]>(
    localStorage.getItem('measurers')
      ? JSON.parse(localStorage.getItem('measurers')!)
      : ([] as Measurer[]),
  );

  useEffect(() => {
    const storedPeriod = localStorage.hasOwnProperty('period');
    if (storedPeriod) {
      const period = localStorage.getItem('period');
      setSelectedPeriodFilter(JSON.parse(period!));
      handleChangeFilter(JSON.parse(period!));
    }
  }, []);

  const setSelectedInfosToLocalStorage = (
    measurers: Measurer[],
    dateRange: DateRange,
  ) => {
    localStorage.setItem('measurers', JSON.stringify(measurers));
    localStorage.setItem('dateRange', JSON.stringify(dateRange));
    localStorage.setItem('measurementPath', JSON.stringify(window.location));
  };

  const isComparing = useMemo(() => {
    return checked && measurersDisplayed.length === 1;
  }, [checked, measurersDisplayed]);

  useEffect(() => {
    setSelectedInfosToLocalStorage(measurersDisplayed, dateRange);
  }, [measurersDisplayed, dateRange]);

  const getStatisticsForOneMeasurer = async (
    measurer: Measurer,
    params: any,
    isComparing: boolean,
  ) => {
    const measurementStatisticRequest = new MeasurementStatisticsRequest();
    measurementStatisticRequest.withMeasurerIdInsideFilters('' + measurer.id);
    measurementStatisticRequest.periodicity = params.periodicity;

    measurementStatisticRequest.beginPeriod = Number(
      formatDateFromPeriodicity(
        isComparing
          ? moment(
              queryParams.dateRange.beginDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).subtract(1, 'm')
          : params.dateRange.beginDate,
        params.periodicity,
      ),
    );

    measurementStatisticRequest.endPeriod = Number(
      formatDateFromPeriodicity(
        isComparing
          ? moment(
              queryParams.dateRange.endDate,
              'YYYY-MM-DDTHH:mm:ssZ',
            ).subtract(1, 'm')
          : params.dateRange.endDate,
        params.periodicity,
      ),
    );

    try {
      const res = await measurementService.getStatistics(
        measurementStatisticRequest,
      );

      if (isComparing) {
        res.map((statistic) => {
          const periodString = statistic.period.toString();
          const date = moment(periodString, 'YYYYMMDDHH');
          const nextYear = date.add(1, 'm').format('YYYYMMDDHH');
          statistic.period = Number(nextYear);
          return statistic;
        });
      }
      return { measurer, data: res };
    } catch (error) {
      throw error;
    }
  };

  const getChroniclesForOneMeasurer = async (
    measurer: Measurer,
    params: any,
  ) => {
    const measurementRequest = new MeasurementRequest();

    measurementRequest.measurerId = measurer.id;
    measurementRequest.beginDate = params.dateRange.beginDate.format(
      'YYYY-MM-DDTHH:mm:ssZ',
    );
    measurementRequest.endDate = params.dateRange.endDate.format(
      'YYYY-MM-DDTHH:mm:ssZ',
    );
    //measurementRequest.pageSize = 100000;
    measurementRequest.orderByProperty = 'measurementDate';
    measurementRequest.orderByDirection = OrderByDirection.DESC;

    try {
      const res = await measurementService.getChronicles(measurementRequest);
      return { measurer, data: res };
    } catch (error) {
      throw error;
    }
  };

  const getAlarmTriggerForOneMeasurer = async ({ queryKey }: any) => {
    const alarmTriggerRequest = new AlarmTriggerRequest();
    const measurerId = queryKey[1];
    alarmTriggerRequest.withMeasurerId(measurerId.toString());
    try {
      return await alarmTriggerService.getAllByMeasurerId(alarmTriggerRequest);
    } catch (error) {
      throw error;
    }
  };

  const valueQueries = useQueries(
    measurersDisplayed
      ?.map((displayedMeasurer) => {
        const queries = [
          {
            queryKey: [
              'measurementsStatistics',
              displayedMeasurer.id,
              queryParams.dateRange.beginDate.toISOString(),
              queryParams.dateRange.endDate.toISOString(),
              queryParams.periodicity,
            ],
            queryFn: () =>
              getStatisticsForOneMeasurer(
                displayedMeasurer,
                queryParams,
                false,
              ),
            cacheTime: 0,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
          },
        ];
        if (isComparing) {
          queries.push({
            queryKey: [
              'additionalQuery',
              displayedMeasurer.id,
              queryParams.dateRange.beginDate.toISOString(),
              queryParams.dateRange.endDate.toISOString(),
              queryParams.periodicity,
            ],
            queryFn: () =>
              getStatisticsForOneMeasurer(displayedMeasurer, queryParams, true),
            cacheTime: 0,          // No caching
            refetchOnWindowFocus: true,
            refetchOnMount: true,
          });
        }
        return queries;
      })
      .flat(),
  );

  const valueArray = useMemo(() => {
    return chain(valueQueries).filter('isFetched').map('data').value();
  }, [valueQueries]);

  const alarmTriggerQueries = useQueries(
    measurersDisplayed?.map((displayedMeasurer) => {
      return {
        queryKey: ['measurementsTriggers', displayedMeasurer.id],
        queryFn: getAlarmTriggerForOneMeasurer,
        cacheTime: 5,
      };
    }),
  );

  const chronicleSeries = useQueries(
    displayChronicles
      ? measurersDisplayed?.map((displayedMeasurer) => {
          return {
            queryKey: [
              'measurementChronicles',
              displayedMeasurer.id,
              queryParams.dateRange.beginDate.toISOString(),
              queryParams.dateRange.endDate.toISOString(),
            ],
            queryFn: () =>
              getChroniclesForOneMeasurer(displayedMeasurer, queryParams),
          };
        })
      : [],
  );

  const chronicleValues = useMemo(() => {
    const allValues = chain(chronicleSeries)
      .filter('isFetched')
      .map('data')
      .value();

    let outData: any = [];
    forEach(allValues, (item) => {
      if (!item) return;
      const measurer = item.measurer;
      const tmpValues = map(item.data, (itemData) => {
        return {
          ...itemData,
          measurementDate: moment(itemData.measurementDate).toDate(),
          deviceName: measurer.device.deviceName,
          measurer: getLabelFromMeasurer(measurer, t),
        };
      });
      outData = [...outData, ...tmpValues];
    });
    return outData;
  }, [chronicleSeries]);

  const alarmTriggers: any[] = chain(alarmTriggerQueries)
    .filter('isFetched')
    .map('data')
    .value();

  // pour récupérer tous les measurer disponibles
  const { data: measurers } = useQuery('measurers_in_measurements', () =>
    measurerService
      .getAll()
      .then((res) =>
        orderBy(keepOnlyCalculators(res), ['device.deviceIdentifier']),
      ),
  );



  const measurerListFiltered = useMemo(() => {
    if(!selectedDevice) return [];
    return measurers?.filter((mesurer:Measurer)=> mesurer.device?.id === selectedDevice.id).filter(
      (measurer) =>
        !measurersDisplayed.some((displayed) => displayed.id === measurer.id),
    );
  }, [measurers, measurersDisplayed, selectedDevice]);

  const handleAddMeasurer = () => {
    if (!isUndefined(measurerSelected)) {
      setMeasurersDisplayed([...measurersDisplayed, measurerSelected!]);
      setMeasurerSelected(null);
    }
  };

  const removeMeasurerFromGraph = (measurer: Measurer) => {
    if (isComparing) {
      setChecked(false);
    }
    //j'enleve le measurer que je supprime de la liste MeasurersDisplayed
    const newMeasurersList = filter(
      measurersDisplayed,
      (m) => !isEqual(m.id, measurer.id),
    );
    setMeasurersDisplayed(newMeasurersList);
  };

  // Gestion des filtres de date
  const handleChangeBeginDatePicker = (beginDate: Moment) => {
    setSelectedPeriodFilter('');
    localStorage.setItem('period', JSON.stringify(''));
    if (beginDate?.isValid()) {
      setDateRange({
        ...dateRange,
        beginDate: beginDate,
      });
    }
    if (beginDate?.isAfter(dateRange.endDate)) {
      setDateRange({
        ...dateRange,
        endDate: moment(dateRange.beginDate).add(1, 'M'),
      });
    }
  };

  const handleChangeEndDatePicker = (endDate: Moment) => {
    setSelectedPeriodFilter('');
    localStorage.setItem('period', JSON.stringify(''));
    if (endDate?.isValid()) {
      setDateRange({
        ...dateRange,
        endDate: endDate,
      });
    }
    if (endDate?.isBefore(dateRange.beginDate)) {
      setDateRange({
        ...dateRange,
        beginDate: moment(dateRange.endDate).subtract(1, 'M'),
      });
    }
  };

  const handleChangeFilter = (selectedFilter: string | null) => {
    localStorage.setItem('period', JSON.stringify(selectedFilter));
    if (selectedFilter) {
      setSelectedPeriodFilter(selectedFilter);
    }

    const newEndDate = moment();
    const currentYear = newEndDate.year();

    switch (selectedFilter) {
      case DAYS:
        setDateRange({
          beginDate: newEndDate.subtract(7, 'days'),
          endDate: moment(),
        });
        break;
      case WEEK:
        setDateRange({
          beginDate: newEndDate.subtract(14, 'days'),
          endDate: moment(),
        });
        break;
      case MONTH:
        setDateRange({
          beginDate: newEndDate.subtract(1, 'months'),
          endDate: moment(),
        });
        break;
      case SEMESTER:
        setDateRange({
          beginDate: newEndDate.subtract(6, 'months'),
          endDate: moment(),
        });
        break;
      case YEAR:
        setDateRange({
          beginDate: newEndDate.subtract(12, 'months'),
          endDate: moment(),
        });
        break;
      case AEC:
        setDateRange({
          beginDate: moment({ year: currentYear, month: 0, day: 1 }),
          endDate: moment(),
        });
        break;
      case MAX:
        setDateRange({
          beginDate: moment({ year: 2015, month: 0, day: 1 }),
          endDate: moment(),
        });
        break;
    }
  };

  const createMenuItemForPeriodFilter = (filter: string) => {
    return (
      <MenuItem value={filter}>
        {t(`measurement.filterPeriod.${filter}`)}
      </MenuItem>
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    handleChangeFilter(event.target.value as string);
  };

  const isAddButtonDisabled = useMemo(() => {
    return isEmpty(measurerListFiltered) || !measurerSelected || isComparing;
  }, [measurerListFiltered, measurerSelected, isComparing]);

  const filteredDevices = useMemo(() => {
    if(isDevicesLoading) return [];
    if(!selectedSite) return [];
    return filter(devices, (device:Device)=> device.location?.parentLocation?.id === selectedSite.id);
  }, [devices, selectedSite, isDevicesLoading]);

  const sortedMeasurerList = measurerListFiltered?.sort((a, b) => {
    const labelA = getLabelFromMeasurer(a, t).toLowerCase();
    const labelB = getLabelFromMeasurer(b, t).toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });

  return (
    <PageContainer
      bgColor={theme.palette.background.default}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} variant="h6">
            {t('navigationMenu.measures')}
          </Typography>
        </Box>
      }
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          m: theme.spacing(2),
          p: theme.spacing(2),
          mt: theme.spacing(0),
        }}
      >
        <Grid item xs={12}>
          <Grid container sx={{ mt: theme.spacing(2) }}>
            <Grid item xs={2} sx={{ pr: theme.spacing(2) }}>
              <ButtonCustom
                fullWidth={true}
                disabled={isComparing}
                onClick={() => setDisplayChronicles(!displayChronicles)}
                label={
                  displayChronicles
                    ? t('measurement.seeGraph')
                    : t('measurement.seeChronicles')
                }
              />
            </Grid>
            <Grid item xs={5}>
              <Grid container sx={{ display: 'flex', ml: theme.spacing(2) }}>
                <Grid item xs={10} sx={{display:'flex', gap:1}}>
                  <SelectCustom
                    value={selectedSite ? selectedSite : ''}
                    disabled={isSitesLoading}
                    label={t('location.site')!}
                    onChange={(e:any)=>{
                      setSelectedSite(e.target.value);
                      setMeasurerSelected(null);
                      setSelectedDevice(null);
                    }}
                    name={'site'}
                    values={sites || []}
                    valueToId={(site: Site) => site.id}
                    valueToLabel={(site: Site) => {
                      return site.locationName;
                    }}
                    sx={{ width: '280px'}}
                  />
                  <SelectCustom
                    value={selectedDevice ? selectedDevice : ''}
                    disabled={!filteredDevices.length}
                    label={t('device.device')!}
                    onChange={(e:any)=>{
                      setSelectedDevice(e.target.value)
                      setMeasurerSelected(null);}
                    }
                    name={'device'}
                    values={filteredDevices || []}
                    valueToId={(device: Device) => device.id}
                    valueToLabel={(device: Device) => {
                      return `${device.location.locationName} ${device.deviceName}`;
                    }}
                    sx={{ width: '280px' }}
                  />
                  <SelectCustom
                    value={measurerSelected ? measurerSelected : ''}
                    disabled={!sortedMeasurerList?.length}
                    label={t('measurerCategory.sensor')!}
                    onChange={(e:any)=>setMeasurerSelected(e.target.value)}
                    name={'measurer'}
                    values={sortedMeasurerList || []}
                    valueToId={(measurer: Measurer) => measurer.id}
                    valueToLabel={(measurer: Measurer) => {
                      return getLabelFromMeasurer(measurer, t);
                    }}
                    sx={{ width: '280px' }}
                  />

                  {/*<Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={sortedMeasurerList || []}
                    value={measurerSelected}
                    onChange={(event: any, newValue: Measurer | null) => {
                      setMeasurerSelected(newValue);
                    }}
                    getOptionLabel={(measurer: Measurer) =>
                      generateFullName(measurer, t)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('navigationMenu.measurers')}
                      />
                    )}
                  />*/}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    disabled={isAddButtonDisabled}
                    onClick={handleAddMeasurer}
                  >
                    <AddCircle
                      color={isAddButtonDisabled ? 'disabled' : 'primary'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <DatePickerCustom
                    sx={{ mr: 1 }}
                    value={dateRange.beginDate}
                    label={'measurement.dateRange.beginDate'}
                    name={'startDate'}
                    onChange={handleChangeBeginDatePicker}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePickerCustom
                    sx={{ mr: 1 }}
                    value={dateRange.endDate}
                    label={'measurement.dateRange.endDate'}
                    name={'endDate'}
                    onChange={handleChangeEndDatePicker}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label" sx={{ lineHeight: '14px' }}>
                      {t('measurement.period')}
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      size={'small'}
                      value={selectedPeriodFilter}
                      onChange={handleChange}
                      input={<OutlinedInput label={t('measurement.period')} />}
                    >
                      {createMenuItemForPeriodFilter(DAYS)}
                      {createMenuItemForPeriodFilter(WEEK)}
                      {createMenuItemForPeriodFilter(MONTH)}
                      {createMenuItemForPeriodFilter(SEMESTER)}
                      {createMenuItemForPeriodFilter(YEAR)}
                      {createMenuItemForPeriodFilter(AEC)}
                      {createMenuItemForPeriodFilter(MAX)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2} sx={{ pr: theme.spacing(2) }}>
              <Grid container>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'compareMeasures'}
                        checked={checked}
                        disabled={measurersDisplayed.length !== 1}
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label={
                      <Typography variant="body2" color="textSecondary">
                        {t('measurement.compareMeasures')}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              {!isEmpty(measurersDisplayed) && (
                <Grid container>
                  {measurersDisplayed?.map((measurer: Measurer) => {
                    return (
                      <Grid item key={measurer.id} xs={12}>
                        <Paper
                          elevation={2}
                          sx={{
                            mt: theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'center',
                            height: 'fit-content',
                            width: 'fit-container',
                          }}
                        >
                          <Box
                            sx={{
                              p: theme.spacing(2),
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                mr: theme.spacing(2),
                                overflow: 'hidden',
                              }}
                            >
                              {measurer?.measurerLabel ? (
                                <>
                                  {measurer.device.deviceName}
                                  {' > '}
                                  {measurer.measurerLabel}
                                </>
                              ) : (
                                <>
                                  {measurer.device.deviceName}
                                  {' > '}
                                  {t(
                                    'measurementType.' +
                                      measurer.measurementType.toLowerCase(),
                                  )}
                                </>
                              )}
                            </Typography>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeMeasurerFromGraph(measurer)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                  <Button
                    onClick={() => {
                      setStatsDialogOpen(true);
                    }}
                    variant="contained"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      mt: theme.spacing(2),
                      textTransform: 'none',
                    }}
                  >
                    <QueryStatsIcon />
                    {t('measurement.statistics')}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate('/');
                    }}
                    variant="contained"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      mt: theme.spacing(2),
                      textTransform: 'none',
                    }}
                  >
                    <MapIcon />
                    {t('measurement.gotoMap')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={10}>
              {displayChronicles ? (
                <Chronicles measurements={chronicleValues} />
              ) : (
                <GraphAMCharts
                  measurements
                  measurementList={valueArray}
                  alarmTriggerList={alarmTriggers}
                  periodicity={queryParams.periodicity}
                  t={t}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <StatisticsDialog
        isOpen={statsDialogOpen}
        setOpen={setStatsDialogOpen}
        measurementsArray={valueArray}
        dateRange={dateRange}
      />
      <FileViewerCustom measurers={measurersDisplayed} />
    </PageContainer>
  );
}

export default Measurements;
