import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5locales_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import * as am5xy from '@amcharts/amcharts5/xy';
import { useTheme } from '@mui/material';
import { colorPalette, graphLineAlarm } from 'constants/ColorsForGraph';
import { Periodicity } from 'constants/Periodicity';
import { forEach, get, map, toLower } from 'lodash';
import moment from 'moment';
import { useLayoutEffect } from 'react';
import { getLabelFromMeasurer } from 'utils/MeasurerUtils';
import { SCREEN_HEIGHT } from '../../constants/ScreenHeight';
import useScreenHeight from '../../hooks/useScreenHeight';
import { handleUnit } from 'utils/UnitsConverter';
import { useTranslation } from 'react-i18next';

const CHART_ID = 'data_chart';

const convertPeriodToTimeStamp = (period: number) => {
  const strPeriod = '' + period;
  let dateStr = '';
  if(strPeriod.length === 6){
    dateStr = `${strPeriod.substring(0, 4)}W${strPeriod.substring(4)}`;
  }
  else{
    dateStr = `${strPeriod.substring(0, 8)}T${strPeriod.substring(8)}00`;
  }
  return moment.utc(dateStr).toDate().getTime();
};

const formatDateOption:Intl.DateTimeFormatOptions = {
  dateStyle:'short',
  timeStyle:'short',
}

function languageToLocale(language:string):string{
  if(language === 'fr' || language === 'fr-FR') return 'fr-FR';
  if(language === 'es'  || language === 'es-ES') return 'es-ES';
  return 'en-US';
}

function createBarChartAndSeries(
  root: am5.Root,
  chart: am5xy.XYChart,
  xAxis: any,
  opposite: any,
  valueYField: string,
  dataSet: any,
  color: any,
  name: string,
  inversed:boolean,
) {
  let yRenderer = am5xy.AxisRendererY.new(root, {
    opposite: opposite,
    inversed: inversed,
  });
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
    }),
  );
  // Add series -> line
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      name,
      valueYField: valueYField,
      valueXField: 'date',
      legendValueText: '{valueY}',
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        labelText: '{formattedDate} - {valueY} {unit}',
      }),
      stroke: color,
      fill: color,
    }),
  );

  yRenderer.grid.template.set('strokeOpacity', 0.05);
  yRenderer.labels.template.set('fill', color);
  yRenderer.setAll({
    stroke: color,
    strokeOpacity: 1,
    opacity: 1,
  });
  series.data.setAll(dataSet);

  return yAxis;
}
function createAxisAndSeries(
  root: am5.Root,
  chart: am5xy.XYChart,
  xAxis: any,
  opposite: any,
  valueYField: string,
  dataSet: any,
  color: any,
  name: string,
  inversed:boolean
) {
  let yRenderer = am5xy.AxisRendererY.new(root, {
    opposite: opposite,
    inversed: inversed
  });
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
    }),
  );
  if(opposite){
    yAxis.children.push(am5.Label.new(root, {
      text: name,
      textAlign: 'center',
      fill: color,
      y: am5.p50,
      rotation: -90,
      fontWeight: 'bold'
    }));
  } else {
    yAxis.children.unshift(am5.Label.new(root, {
      text: name,
      textAlign: 'center',
      fill: color,
      y: am5.p50,
      rotation: -90,
      fontWeight: 'bold'
    }));
  }

  // Add series -> line
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series = chart.series.push(
    am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      name,
      valueYField: valueYField,
      valueXField: 'date',
      legendValueText: '{valueY}',
      stroke: color,
      fill: color,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: 'horizontal',
        labelText: '{formattedDate} : {valueY} {unit}',
      }),
    }),
  );
  series.strokes.template.setAll({ strokeWidth: 1 });

  yRenderer.grid.template.set('strokeOpacity', 0.05);
  yRenderer.labels.template.set('fill', color);

  yRenderer.setAll({
    stroke: color,
    strokeOpacity: 1,
    opacity: 1,
  });
  series.data.setAll(dataSet);

  return yAxis;
}

function createRange(item: any, yAxis: any) {
  const alarmColor = am5.color(graphLineAlarm);
  if (item) {
    let range = yAxis.createAxisRange(
      yAxis.makeDataItem({
        value: item.thresholdValue.fieldValue,
      }),
    );
    if (range !== undefined) {
      range.get('grid')!.setAll({
        forceHidden: false,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: alarmColor,
      });
    }
  }
}

const getTimeUnitFromPeriodicity = (periodicity: Periodicity): any => {
  return toLower(periodicity);
};

const getAmChartsLocaleFromLanguage = (usedLanguage:string) => {
  console.log('Language', usedLanguage);
  if(usedLanguage === 'fr' || usedLanguage === 'fr-FR') return am5locales_fr_FR;
  if(usedLanguage === 'es' || usedLanguage === 'es-ES') return am5locales_es_ES;
  return am5locales_en_US;

}

export default function GraphAMCharts({
  measurements,
  measurementList,
  alarmTriggerList,
  periodicity,
  t,
}: any) {
  const theme = useTheme();
  const screenHeight = useScreenHeight();
  const { i18n } = useTranslation();
  const usedLanguage = i18n.language;

  const graphHeight = measurements
    ? screenHeight - SCREEN_HEIGHT.HEADER - 150
    : (screenHeight - SCREEN_HEIGHT.HEADER) / 2 - 80;

  useLayoutEffect(() => {
    let root = am5.Root.new(CHART_ID);
    root.locale = getAmChartsLocaleFromLanguage(usedLanguage);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomY',
        layout: root.verticalLayout,
      }),
    );

    // Create Xaxes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: getTimeUnitFromPeriodicity(periodicity),
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    // Add export menu
    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomY',
      }),
    );
    cursor.lineX.set('visible', false);
    forEach(measurementList, (measurement, key) => {
      const index = Number(key);
      const mapToMeasurement = map(measurement.data, (measurementItem) => {
        return {
          ...measurementItem,
          date: convertPeriodToTimeStamp(measurementItem.period),
          formattedDate: new Date(convertPeriodToTimeStamp(measurementItem.period)).toLocaleString(languageToLocale(usedLanguage), formatDateOption),
          unit: handleUnit(measurementItem.measurementUnit)
        };
      });
      const isInvertBarchart =
        get(measurement, 'measurer.measurementType') === 'WATER_DEPTH_RELATIVE';
      const isBarChart = false;
      const creationFunction: any = isBarChart
        ? createBarChartAndSeries
        : createAxisAndSeries;
      const yAxis = creationFunction(
        root,
        chart,
        xAxis,
        index % 2 === 1,
        'averageValue',
        mapToMeasurement,
        colorPalette[index],
        getLabelFromMeasurer(measurement.measurer, t),
        isInvertBarchart
      );
      const alarmTriggers = get(alarmTriggerList, index);
      if (alarmTriggers) {
        forEach(alarmTriggers, (item: any) => createRange(item, yAxis));
      }
    });

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        centerX: am5.percent(0),
        x: am5.percent(0),
        y: am5.percent(95),
        marginBottom: 10,
      }),
    );

    legend.data.setAll(
      map(measurementList, (measurement, key) => {
        const index = Number(key);
        return {
          name: getLabelFromMeasurer(measurement.measurer, t),
          color: colorPalette[index],
        };
      }),
    );

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
        y: am5.percent(100),
        dy: -20,
        centerY: am5.percent(100),
      }),
    );

    return () => {
      root.dispose();
    };
  }, [measurementList, alarmTriggerList, usedLanguage]);

  return (
    <div
      id={CHART_ID}
      style={{
        width: '100%',
        height: graphHeight,
        margin: `${theme.spacing(2)}px 0`,
      }}
    />
  );
}
